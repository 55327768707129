import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { hot } from 'react-hot-loader/root';
import {
  FlashMessage,
  Settings as FlashSettings,
} from 'shared/FlashMessage/FlashMessage';
import { pages } from './pages';
import { Header, Footer } from './layout';
import Providers from 'core/Providers';
import ScrollToTop from 'core/ScrollToTop';
import HotLoader from 'core/HotLoader';
import { UserContextUpdater } from './core/context/user';
import { User } from 'core/context/UserContextHandler';
import { BreakpointProvider } from 'react-socks';
import { Flags } from 'utils/FeatureFlagContainer/FeatureFlagContainer';
import { EnvVariables } from 'utils/EnvVariableContainer/EnvVariableContainer';
import { Router } from './Router';
import { BootstrapUser } from 'types/bootstrap';
import SegmentConsentManager from './segmentConsentManager';
import { LastLocationProvider } from 'react-router-last-location';
import './main.scss';
import { ROUTES } from 'utils/constants';

export type Props = {
  flags: Flags;
  config: {
    csrfToken: string;
    SEGMENT_API_KEY?: string;
  };
  relayEnvironment: any; // TODO: fix when converted to TS
  bootstrapUser?: BootstrapUser;
  contextUser: User | null;
  error: string | null;
};

const Main: React.FC<Props> = props => {
  const {
    config,
    flags,
    relayEnvironment,
    bootstrapUser,
    contextUser,
    error,
  } = props;
  if (error) throw error;
  const [flashSettings, renderFeedback] = useState<FlashSettings>(null);
  const envVariables: EnvVariables = {
    googleAPIKey: bootstrapUser?.googleAPIKey || null,
  };

  const { pathname, search } = useLocation();

  const shouldShowBodyOnlyPaths = [
    '/login/',
    '/login',
    '/login/exists',
    '/sso-login',
    '/reset-password',
    '/reset-password/reset',
    '/sign-up/expert',
    '?preview=true',
    '/sign-up',
    '/sign-up/expert/application',
  ];

  const shouldShowBodyOnly =
    shouldShowBodyOnlyPaths.includes(pathname) ||
    shouldShowBodyOnlyPaths.includes(search) ||
    false;

  const isExpertApplication = pathname === ROUTES.signUp.expertApplication;
  return (
    <Providers
      config={config}
      flags={flags}
      envVariables={envVariables}
      renderFeedback={renderFeedback}
      relayEnvironment={relayEnvironment}
      user={bootstrapUser}
    >
      <LastLocationProvider>
        <BreakpointProvider>
          <UserContextUpdater user={contextUser}>
            {!shouldShowBodyOnly && !isExpertApplication && (
              <Header user={bootstrapUser} />
            )}
            <div className={(!shouldShowBodyOnly && 'c-letterbox__main') || ''}>
              <div
                className={
                  (!shouldShowBodyOnly && 'l-main-content-outer') || ''
                }
              >
                <FlashMessage
                  settings={flashSettings}
                  onHide={() => renderFeedback(null)}
                />
                <main
                  role="main"
                  className={(!shouldShowBodyOnly && 'l-main-content') || ''}
                >
                  <HotLoader>
                    <ScrollToTop>
                      <Router
                        flags={flags}
                        pages={pages}
                        userToken={bootstrapUser?.userToken}
                      />
                    </ScrollToTop>
                  </HotLoader>
                </main>
              </div>
            </div>
            {!shouldShowBodyOnly && !isExpertApplication && <Footer />}
            {process.env.NODE_ENV != 'development' && <SegmentConsentManager />}
          </UserContextUpdater>
        </BreakpointProvider>
      </LastLocationProvider>
    </Providers>
  );
};

export default hot(Main);
